var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    [
      _vm._t("default"),
      _c("ck-radio-input", {
        attrs: {
          value: _vm.access,
          id: _vm.customEligibilitySlug + "_access",
          label: "Access to the service",
          hint:
            "Changing access to all will remove current selections and custom values",
          options: _vm.accessOptions,
          error: null
        },
        on: { input: _vm.onUpdateAccess }
      }),
      _vm.access === "some"
        ? _c("ck-taxonomy-tree", {
            attrs: {
              taxonomies: _vm.eligibilityTaxonomy.children,
              checked: _vm.serviceEligibilityTypes.taxonomies,
              error: _vm.errors,
              filteredTaxonomyIds: true
            },
            on: { update: _vm.onUpdateTaxonomies }
          })
        : _vm._e(),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _vm.access === "some"
        ? _c("ck-text-input", {
            attrs: {
              value: _vm.customEligibilityValue,
              id: "custom",
              label: "Custom value",
              hint:
                "If the service eligibility does not fit into the " +
                _vm.eligibilityTaxonomy.name +
                " taxonomies, enter a custom value here",
              error: _vm.errors.get("custom")
            },
            on: { input: _vm.onUpdateCustom }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }