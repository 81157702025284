var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.auth.isGlobalAdmin
        ? _c(
            "div",
            [
              _c(
                "gov-checkboxes",
                _vm._l(_vm.combinedTags, function(tag) {
                  return _c("gov-checkbox", {
                    key: tag.slug,
                    attrs: {
                      id: tag.slug,
                      value: _vm.serviceTagSlugs.includes(tag.slug),
                      name: tag.slug,
                      label: tag.label
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateTags({ tag: tag, enabled: $event })
                      }
                    }
                  })
                }),
                1
              ),
              _c(
                "gov-form-group",
                [
                  _c("ck-text-input", {
                    attrs: {
                      id: "new-tag",
                      label: "Create new tag",
                      error: _vm.newTagError
                    },
                    model: {
                      value: _vm.newTag.label,
                      callback: function($$v) {
                        _vm.$set(_vm.newTag, "label", $$v)
                      },
                      expression: "newTag.label"
                    }
                  }),
                  _c(
                    "gov-button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.updateTags({ tag: _vm.newTag })
                        }
                      }
                    },
                    [_vm._v("\n        Add tag\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._l(_vm.serviceTags, function(serviceTag) {
            return _c("gov-tag", { key: serviceTag.slug }, [
              _vm._v("\n    " + _vm._s(serviceTag.label) + "\n  ")
            ])
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }