var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.usefulInfos, function(usefulInfo, index) {
        return _c(
          "gov-inset-text",
          { key: usefulInfo.index },
          [
            _c("ck-select-input", {
              attrs: {
                value: usefulInfo.title,
                id: "useful_infos." + index + ".title",
                label: "Pick a title from the drop down:",
                options: _vm.usefulInfoTitleOptions,
                error: _vm.errors.get("useful_infos." + index + ".title")
              },
              on: {
                input: function($event) {
                  return _vm.onTitleInput({ index: index, value: $event })
                }
              }
            }),
            _c("ck-wysiwyg-input", {
              attrs: {
                value: usefulInfo.description,
                id: "useful_infos." + index + ".description",
                label: "Description",
                hint:
                  "Provide detail to the title above. For example, if you picked ‘Parking’ you might say “There is no parking available on site, however there is pay and display opposite”.",
                maxlength: 1000,
                error: _vm.errors.get("useful_infos." + index + ".description"),
                extensions: _vm.extensions
              },
              on: {
                input: function($event) {
                  return _vm.onDescriptionInput({ index: index, value: $event })
                }
              }
            }),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDeleteUsefulInfo(index)
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "gov-button",
            { on: { click: _vm.onAddUsefulInfo } },
            [
              _vm.usefulInfos.length === 0
                ? [_vm._v("Add useful info")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }