var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.offerings, function(offering, index) {
        return _c(
          "gov-inset-text",
          { key: offering.index },
          [
            _c("ck-text-input", {
              attrs: {
                value: offering.offering,
                id: "offerings." + index + ".offering",
                label: "Offering",
                error: _vm.errors.get("offerings." + index + ".offering")
              },
              on: {
                input: function($event) {
                  return _vm.onOfferingInput({ index: index, value: $event })
                }
              }
            }),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDeleteOffering(index)
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "gov-button",
            { on: { click: _vm.onAddOffering } },
            [
              _vm.offerings.length === 0
                ? [_vm._v("Add offering")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }