var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Good to know")]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "What do people want to know about your " +
                    _vm._s(_vm.type) +
                    "."
                )
              ]),
              _c("useful-infos-input", {
                attrs: { "useful-infos": _vm.useful_infos, errors: _vm.errors },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:useful_infos", $event)
                  },
                  clear: function($event) {
                    return _vm.$emit("clear", $event)
                  }
                }
              }),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }