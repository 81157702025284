var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.socialMedias, function(socialMedia, index) {
        return _c(
          "gov-inset-text",
          { key: socialMedia.index },
          [
            _c("ck-select-input", {
              attrs: {
                value: socialMedia.type,
                id: "social_media." + index + ".type",
                label: "Platform:",
                options: _vm.socialMediaTypeOptions,
                error: _vm.errors.get("social_media." + index + ".type")
              },
              on: {
                input: function($event) {
                  return _vm.onTypeInput({ index: index, value: $event })
                }
              }
            }),
            _c("ck-text-input", {
              attrs: {
                value: socialMedia.url,
                id: "social_medias." + index + ".url",
                label: "Link to your page:",
                hint: _vm.linkHint(socialMedia.type),
                type: "url",
                error: _vm.errors.get("social_medias." + index + ".url")
              },
              on: {
                input: function($event) {
                  return _vm.onUrlInput({ index: index, value: $event })
                }
              }
            }),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDeleteSocialMedia(index)
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "gov-button",
            { on: { click: _vm.onAddSocialMedia } },
            [
              _vm.socialMedias.length === 0
                ? [_vm._v("Add social media")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }