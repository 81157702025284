var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v("Who is eligible for your " + _vm._s(_vm.type) + "?")
      ]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        Use this section to help indicate who should be using your " +
                    _vm._s(_vm.type) +
                    ".\n        If any of these criteria do not apply, please leave them blank.\n      "
                )
              ]),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _vm._l(_vm.eligibilityTypes, function(parent) {
                return _c(
                  "service-eligibility-input",
                  {
                    key: parent.id,
                    attrs: {
                      eligibilityTaxonomy: parent,
                      serviceEligibilityTypes: _vm.serviceEligibilityTypes,
                      errors: _vm.errors
                    },
                    on: {
                      "update:taxonomies":
                        _vm.updateServiceEligibilityTaxonomies,
                      "update:custom": _vm.updateServiceEligibilityCustom
                    }
                  },
                  [
                    _c("gov-heading", { attrs: { size: "m", tag: "h3" } }, [
                      _vm._v(_vm._s(parent.name))
                    ])
                  ],
                  1
                )
              }),
              _vm.errors.has("eligibility_types")
                ? _c("gov-error-message", {
                    attrs: { for: "eligibility_types" },
                    domProps: {
                      textContent: _vm._s(_vm.errors.get("eligibility_types"))
                    }
                  })
                : _vm._e(),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }