var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v("Taxonomies (Tags)")
      ]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "full" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        These are a list of ‘tags’ that are applied to a " +
                    _vm._s(_vm.type) +
                    ". These\n        tags help the " +
                    _vm._s(_vm.type) +
                    " be found in categories and keyword searches.\n      "
                )
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        On creation of a new " +
                    _vm._s(_vm.type) +
                    ", the admin team will select the tags\n        that they feel represent the " +
                    _vm._s(_vm.type) +
                    " offer.\n      "
                )
              ]),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _c(
                "gov-form-group",
                { attrs: { invalid: _vm.errors.has("category_taxonomies") } },
                [
                  _c("ck-taxonomy-input", {
                    attrs: {
                      value: _vm.category_taxonomies,
                      root: "categories",
                      error: _vm.errors.get("category_taxonomies"),
                      disabled: !_vm.isGlobalAdmin
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("update:category_taxonomies", $event)
                      },
                      clear: function($event) {
                        return _vm.$emit("clear", "category_taxonomies")
                      }
                    }
                  }),
                  _vm.errors.has("category_taxonomies")
                    ? _c("gov-error-message", {
                        attrs: { for: _vm.category_taxonomies },
                        domProps: {
                          textContent: _vm._s(
                            _vm.errors.get("category_taxonomies")
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }